import { bootstrap } from '../assets';
import { html5 } from '../assets';
import { git } from '../assets';
import { css } from '../assets';
import { saas } from '../assets';
import { javascript } from '../assets';
import { react } from '../assets';

export default function Tech(){
    return(
        <>
            <div className='tech'>
                <ul className='tech-items flex small:flex-wrap small:justify-center medium:gap-x-5 small:gap-y-5 small:gap-x-5 justify-evenly my-10 p-8'>
                    <li className='tech-item'><img src={html5}></img></li>
                    <li className='tech-item'><img src={css}></img></li>
                    <li className='tech-item'><img src={saas}></img></li>
                    <li className='tech-item'><img src={javascript}></img></li>
                    <li className='tech-item'><img src={react}></img></li>
                    <li className='tech-item'><img src={git}></img></li>
                </ul>
            </div>
        </>
    );
}