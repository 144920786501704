export default function About(){
    return(
        <>
        <div className="about" name="about">
            <h1 className="text-xl mb-3">About Me</h1>
            <p>Hello, I am Nurdan, a Front End Developer with 4 years of professional experience. I graduated from Kocaeli University with a degree in Computer Engineering. <br className="small"/>I am highly open to learning and eager to develop new skills. Throughout my career, I have successfully worked in teams, demonstrating my ability to collaborate effectively.<br/><br/>
            When faced with something I don’t know, I make a strong effort to learn and improve. I adapt quickly to new environments and situations. One of my key strengths is my attention to detail, ensuring that the work I produce is thorough and precise. Although this can sometimes slow me down, <br className="small"/>I believe it ensures a high standard of quality in my work.<br/><br/>
            I am continuously working on <br className="medium"/><br className="small"/>self-improvement, especially during my free time, to stay up-to-date and refine my skills.</p>
        </div>
        </>
    );    
}