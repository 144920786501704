import React, { useState, useEffect, useRef } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';

export default function Header() {
  const myRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuButton = document.getElementsByClassName('.menu-button');
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

    useEffect(() => {
        if (myRef.current) {
            myRef.current.style.borderBottomWidth = isMenuOpen ? '0px' : '2px;';
        }
    }, [isMenuOpen]); // This will run every time isMenuOpen changes

    // Sayfa açıldığında veya menü kapandığında arka plan kaymasını engelle
    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
        return () => {
          document.body.style.overflow = 'auto'; // Temizleme işlemi
        };
      }, [isMenuOpen]);

  return (
    <>
      <nav className="navbar border-b-2 border-white" ref={myRef}>
        <ul className="flex small:justify-end flex-row justify-between w-full py-4 px-7">
          <li className='navbar-item'><a href="#">HOME</a></li>
          <Link to="about" smooth={true} duration={500}><li className='navbar-item'><a href="#">ABOUT</a></li></Link>
          <li className='navbar-item'><a href="#">PORTFOLIO</a></li>
          <li className='navbar-item'><a href="#">BLOG</a></li>
          <li className={`menu-button ${isMenuOpen ? 'open' : ''}`}>
          <button onClick={toggleMenu}>
            {isMenuOpen ? <RxCross1 /> : <RxHamburgerMenu />}
          </button>
        </li>
        </ul>
      </nav>

      {/* Mobil Menü */}
      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#" onClick={toggleMenu}>HOME</a></li>
          <li><Link to="about" smooth={true} duration={500} onClick={toggleMenu} offset={isMobile ? -880 : 0}>ABOUT</Link></li>
          <li><a href="#" onClick={toggleMenu}>PORTFOLIO</a></li>
          <li><a href="#" onClick={toggleMenu}>BLOG</a></li>
        </ul>
      </div>
    </>
  );
}
