import  hogarth_logo from '../assets/hogarth_logo.jpg';
import  hairofistanbul_logo from '../assets/hairofistanbul_logo.jpg';
import  heraklet_logo from '../assets/heraklet_logo.jpg';


export default function Experience(){
    return(
        <>
            <div className="experience max-w-lg">
                <h1 className="text-xl mb-3">Work Experience</h1>
                <div className="p-5 border-b-2 border-white flex gap-x-5 items-end bg-zinc-800 mb-3 small:items-center">
                    <img src={hogarth_logo} width="70"></img>
                    <div>
                       <h4>Front End Developer</h4>
                       <span>Hogarth Worldwide</span><br/>
                       <span>July 2022 - Present · 2 years 5 months</span>
                    </div>
                </div>
                <div className="p-5 border-b-2 border-white flex gap-x-5 items-end bg-zinc-800 mb-3 small:items-center">
                    <img src={hairofistanbul_logo} width="70"></img>
                    <div>
                        <h4>Web Developer</h4>
                        <span>Hair of Istanbul</span><br/>
                        <span>May 2021 - May 2022 · 1 year 1 month</span>
                    </div>
                </div>
                <div className="p-5 border-b-2 border-white flex gap-x-5 items-end bg-zinc-800 mb-3 small:items-center">
                    <img src={heraklet_logo} width="70"></img>
                    <div>
                        <h4>Web Developer</h4>
                        <span>Heraklet</span><br/>
                        <span>September 2020 - May 2021 · 9 months</span>
                    </div>
                </div>
            </div>
        </>
    );
}