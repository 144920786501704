import { RiMapPinLine } from "react-icons/ri";
import color_sharp from '../assets/color-sharp.png';
import color_sharp2 from '../assets/color-sharp2.png';
import { MdMailOutline } from "react-icons/md";
import { FaGithub } from "react-icons/fa";
import { SlSocialLinkedin } from "react-icons/sl";
import { SlSocialInstagram } from "react-icons/sl";

export default function Contact(){
    return(
        <>
        <div className="contact flex justify-between" name="contact">
            <div><img src={color_sharp} width={200}></img></div>
            <div className="flex flex-col flex-1 small:pb-[100px] small:flex-none">
            <h1 className="text-xl mb-3 contact-heading">Contact Me</h1>
            <ul>
                <li className="text-white flex gap-x-3 items-center"><MdMailOutline /> <span><a href="mailto:nurdannvaynii@gmail.com" className="text-white text-opacity-70 hover:underline">nurdannvaynii@gmail.com</a></span></li>
                <li className="text-white flex gap-x-3 items-center"><RiMapPinLine /> <span className="text-white text-opacity-70 hover:underline">Istanbul, Türkiye</span></li>
                <li className="text-white flex gap-x-3 items-center"><FaGithub /> <span><a href="https://github.com/vayninurdan" className="text-white text-opacity-70 hover:underline">Check out my GitHub Profile!</a></span></li>
                <li className="text-white flex gap-x-3 items-center"><SlSocialLinkedin /> <span><a href="https://www.linkedin.com/in/nurdanvayni/" className="text-white text-opacity-70 hover:underline">Linkedin</a></span></li>
                <li className="text-white flex gap-x-3 items-center"><SlSocialInstagram /> <span><a href="https://www.instagram.com/nurdannvaynii/" className="text-white text-opacity-70 hover:underline">Instagram</a></span></li>
            </ul>
            </div>
            <div><img src={color_sharp2} width={200}></img></div>
        </div>
        </>
    );
}
