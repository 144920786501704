import Lottie from 'lottie-react';
import { IoIosArrowForward } from "react-icons/io";
import animationData from '../assets/animation.json';
import { Link } from 'react-scroll';

export default function Hero(){
    return(
        <>
        <div className='section-hero'>
        <div className='section-hero-content'>
            <span className='headline'>Hello, I'm Nurdan. Front End Developer</span><br/>
            <span className='headline-text'>Building the visual and interactive part of websites and <br className='large'/>bringing them to life with <br className='medium'/>user-focused designs.</span>
            <div className='buttons'>
            <Link 
              to="contact" 
              smooth={true} 
              duration={500} 
              className='btn' 
            >
              Contact me
            </Link>
            <a className='icon-arrow' href='#'><span>My CV</span> <IoIosArrowForward /></a>
          </div>
        </div>
        </div>
        </>
    );
}
