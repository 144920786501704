import './index.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Tech from './components/Tech';
import Experience from './components/Experience';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Element } from 'react-scroll';

function App() {
  return (
    <>
      <Header/>
      <Hero/>
      <Tech/>
      <div className='container-side-by-side'>
        <About/>
        <Experience/>
      </div>
      <Element name="contact">
        <Contact />
      </Element>
      <Footer/>
    </>
  );
}

export default App;
